<template>
  <Container>
    <Header>
      Brewing
      <template #right>
        <BadgeBtn @click="openBrew()">
          + Brew
        </BadgeBtn>
      </template>
    </Header>
    <BrewTable :brews="popBrews.filter(brew => brew.state == BREW.PLANNING)" />
  </Container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import BrewTable from "../components/tabels/BrewTable.vue"
import comp from "../components/base/"
import { BREW } from "../services/enums"

export default {
  name: "Breing",
  components: {
    ...comp,
    BrewTable,
  },
  setup() {
    return {
      BREW,
    }
  },
  computed: {
    ...mapGetters(["popBrews"]),
  },
  methods: {
    ...mapActions(["openBrew"]),
  },
}
</script>
